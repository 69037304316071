.page_title_custom h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 1.71rem;
	line-height: 28px;
}

@media (max-width: 700px) {
	.page_title_custom h4 {
		font-size: 1.2rem;
	}
}
