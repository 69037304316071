.rse-text-field {
	height: 40px;
	padding: 9.5px 12px;
	border: 1px solid #dfe1e6;
	border-radius: 4px;
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	width: 100%;
	background: var(--dark-bg-text-color);
	color: #253858;
}

.rse-textarea {
	padding: 9.5px 12px;
	border: 1px solid #dfe1e6;
	border-radius: 4px;
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	width: 100%;
	background: var(--dark-bg-text-color);
	color: #253858;
	min-height: 84px;
	margin: 0;
}

.rse-error-field {
	border: 1px solid #de350b;
}

.rse-text-field:focus,
.rse-textarea:focus {
	border: 2px solid #4c9aff;
}

.rse-text-field::placeholder,
.rse-textarea::placeholder {
	color: #7a8699;
}

.rse-text-field:disabled,
.rse-textarea:disabled {
	background: var(--disabled);
	color: #a5adba;
}

.rse-withIcon-field {
	position: relative;
	width: 100%;
	height: 40px;
}

.rse-withIcon-field span {
	position: absolute;
	right: 0;
	display: inline-block;
	height: 100%;
	padding: 10px 10px;
	width: 10%;
	margin-top: 2px;
}

.rse-withIcon-field .rse-text-field {
	padding-right: 9% !important;
	margin-bottom: 0px !important;
}

.rse-field-wrap {
	width: 100%;
}
