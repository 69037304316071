@font-face {
	font-family: "CircularStd";
	src: url("../../assets/fonts/CircularStd-Black.otf"),
		url("../../assets/fonts/CircularStd-Bold.otf"),
		url("../../assets/fonts/CircularStd-Book.otf"),
		url("../../assets/fonts/CircularStd-Medium.otf");
}

/* General Styles */
.rse-button {
	padding: 0.75em;
	width: fit-content;
	height: 40px;
	font-family: "CircularStd";
	font-weight: 500;
	font-size: 1rem;
	outline: none;
	border: none;
	border-radius: 4px;
	margin: 4px;
	white-space: nowrap;
}

.rse-button.link {
	font-size: 1rem;
	background-color: transparent;
	color: var(--primary);
}

.rse-button.disabledButtonLink {
	font-size: 1rem;
	background-color: transparent;
	color: #a5adba;
	font-weight: 450;
}

.rse-button.link:hover {
	color: var(--primary-hover), 100%;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 2px;
}

.rse-button.link a {
	text-decoration: none;
}

.rse-button:disabled,
.rse-button:disabled:hover,
.rse-button.link:disabled,
.rse-button.link:disabled:hover {
	background: var(--disabled);
	color: #a5adba !important;
	font-weight: 500;
	cursor: not-allowed;
}

.rse-loading-button {
	padding-left: 23px;
	padding-right: 23px;
}

/* Button types */

.warning {
	color: var(--text-color);
	background-color: var(--warning);
}

.warning:hover {
	background: var(--warning-hover);
}

.primary {
	background-color: var(--primary);
	color: var(--dark-bg-text-color);
}

.primary:hover {
	background: var(--primary-hover);
}

.standard {
	background: var(--disabled);
	color: #253858;
}

.standard:hover {
	background: #dfe1e6;
}

.standard-two {
	background: var(--disabled);
	color: var(--primary);
}

.standard-two:hover {
	background: #dfe1e6;
}

.standard-danger {
	background: var(--disabled);
	color: var(--danger);
}

.standard-danger:hover {
	background: #dfe1e6;
}

.danger {
	background: var(--danger);
	color: var(--dark-bg-text-color);
}

.danger:hover {
	background: #ff5630;
}

.success {
	background: var(--secondary);
	color: var(--dark-bg-text-color);
}

.success:hover {
	background: #36b37e;
}

.success-two {
	background: #36b37e;
	color: var(--dark-bg-text-color);
}

.success-two:hover {
	background: #8ab393;
}
/* Loading button states */

.rse-loading-button.danger .aui-spinner svg circle,
.rse-loading-button.primary .aui-spinner svg circle,
.rse-loading-button.success .aui-spinner svg circle,
.rse-loading-button.success-two .aui-spinner svg circle {
	stroke: var(--dark-bg-text-color);
}

/* Button Icons */
