.class_personal_container {
	padding: 24px;
	background: var(--white);
	border: 1px solid #ddd;
	border-radius: 2px;
}

.class_list_subcontainer {
	border-right: 1px solid var(--neutral-border);
}

.rcr_title p {
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 24px;
}

.rcr_title2 p {
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 20px;
}

.rcr_title2 h4 {
	font-size: 24px;
	font-style: normal;
	font-weight: 450;
	line-height: 20px;
}

.rcr_title3 h3 {
	font-size: 48px;
	font-style: normal;
	font-weight: 450;
	line-height: normal;
	text-align: center;
}
.rcr_title3 p {
	font-size: 14px;
	font-style: normal;
	font-weight: 450;
	line-height: 20px;
	text-align: center;
}
