.upload_dotted_box {
	width: 100%;
	border: 2px dashed #dfe1e6;
	height: 156px;
	border-radius: 4px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	cursor: pointer;
}

.upload_dotted_box div:nth-of-type(1) {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.upload_dotted_box div:nth-of-type(2) {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.upload_dotted_box div:nth-of-type(2) input {
	width: 100%;
	height: 100%;
}

.upload_dotted_box span {
	cursor: pointer;
	color: var(--primary);
}

.upload_error {
	border: 2px dashed red;
}

.upload_file_info {
	display: inline-block;
	background: var(--disabled);
	border-radius: 4px;
	font-weight: 100 !important;
}

.upload_file_info span {
	font-weight: 450 !important;
}

.signature_view_state {
	background: #f8f8f8;
	padding: 18px 0px;
}

.signature_view_state > div:first-of-type {
	width: 40%;
}

.signature_view_state > div:first-of-type > img {
	max-width: 100%;
	height: 100%;
}

.show_signature_display {
	display: flex;
}

.hide_signature_display {
	display: none;
}
