.scene {
	position: relative;
	width: 100%;
	height: 50vh;
	background-color: var(--success);
	overflow: hidden;
}
.rocket {
	position: relative;
	animation: animate 0.2s ease infinite;
	z-index: 10;
}
.rocket::before {
	content: "";
	position: absolute;
	top: 100px;
	left: 50%;
	transform: translateX(-50%);
	width: 10px;
	height: 100px;
	background: linear-gradient(var(--neutral-10), transparent);
}
.rocket::after {
	content: "";
	position: absolute;
	top: 100px;
	left: 50%;
	transform: translateX(-50%);
	width: 10px;
	height: 100px;
	background: linear-gradient(var(--neutral-10), transparent);
	filter: blur(20px);
}

.star {
	position: absolute;
	top: -250px;
	background: rgba(255, 255, 255, 0.5);
	animation: animateStars linear infinite;
}

.modalDetails {
	position: relative;
	z-index: 5;
	gap: 20px;
}

.modalDetails h4 {
	color: var(--white-color);
	font-size: 2rem;
	opacity: 1;
	animation: textAnimation 2s linear infinite;
}

@media (max-width: 500px) {
	.rocket img {
		width: 50px;
		height: 80px;
	}
	.rocket::after {
		top: 80px;
		height: 80px;
	}
	.rocket::before {
		top: 80px;
		height: 80px;
	}
	.program_card {
		width: 100%;
	}
}

@keyframes animate {
	0%,
	100% {
		transform: translateY(-2px);
	}
	50% {
		transform: translateY(2px);
	}
}
@keyframes animateStars {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100vh);
	}
}
@keyframes textAnimation {
	50% {
		opacity: 0;
	}
}
