.red-global-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
	height: 72px;
	background-color: white;
	box-shadow: 0px 2px 6px rgba(193, 199, 208, 0.4);
	position: sticky;
	z-index: 5;
	top: 0;
}

.red-hamburger-menu {
	padding: 0;
	margin: 0;
	background: none;
	border: none;
}

.res-profile-name{
	font-size: 0.86rem;
	color: var(--light-text-color);
	font-weight: 500;
	line-height: 1;
}

.red-current-module {
	border-left: 1px solid #c1c7d0;
	padding-left: 16px;
}

.red-global-logo {
	margin: 0 16px;
}

.red-global-logo img:nth-child(1) {
	display: inline;
}

.red-global-logo img:nth-child(2) {
	display: none;
}

.red-current-module {
	font-style: normal;
	font-weight: 450;
	font-size: 1.14rem;
	line-height: 24px;
	color: var(--text-color);
}

.global-avatar span {
	font-size: 0.86rem;
	font-weight: 500px;
}

.topnav-btn {
	cursor: pointer;
	padding: 10px 10px;
	position: absolute;
	right: 20px;
}

.topnav-dropdown-box {
	width: 328px;
	border-radius: 3px;
	padding: 20px, 16px;
}

.topnav-dropdown a {
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
}

.res-role {
	width: 300px;
	min-height: 244px;
	background: var(--dark-bg-text-color);
	box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
		0px 0px 1px rgba(9, 30, 66, 0.31);
	border-radius: 3px;
}

.res-user {
	font-style: normal;
	font-weight: bold;
	font-size: 0.86rem;
	line-height: 12px;
	color: var(--light-text-color);
	padding: 28px 0 16px 20px;
	text-transform: capitalize;
}

.res-role-txt {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: #172b4d;
	padding: 0 0 8px 20px;
	cursor: pointer;
	text-decoration: none;
}

.res-role-txt:hover {
	text-decoration: none;
}

.res-roles {
	padding: 0 20px 12px 20px;
	cursor: pointer;
}

.res-roles:hover {
	transform: scale(1.01);
}

.res-roles p {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: #172b4d;
	margin-left: 12px;
}

.res-wrapper {
	border-bottom: 2px solid #dfe1e6;
}

.res-active-role {
	color: #0747a6;
}

.dropdown-menu {
	transition: all 0.5s;
	overflow: hidden;
	transform-origin: top center;
	transform: scale(1, 0);
	display: block;
}

.dropdown-menu:hover {
	transform: scale(1);
}

.global-landing-page-link-container {
	display: flex;
	align-items: center;
}

.cancel-button {
	cursor: pointer;
	margin-left: 30px;
	border: none;
	background-color: transparent;
	margin-top: 30px;
	display: none;
}

.global-landing-page-links {
	border: none;
	background-color: transparent;
	margin-right: 16px !important;
	font-size: 1rem;
	color: #7A869A;
	cursor: pointer;
}

.global-landing-page-links-active {
	color: #253858;
}

.responsive-hamburger-menu {
	display: none;
}

.retract-navigation {
	top: 0%!important;
}

@media(max-width: 834px) {
	.responsive-hamburger-menu {
		display: block;
	}

	.global-landing-page-link-container {
		flex-direction: column;
		align-items: flex-start;
		background-color: #fff;
		position: fixed;
		width: 100%;
		transition: 0.3s ease-in-out;
		height: 100%;
		top: -100%;
		left: 0;
		z-index: 5;
	}

	.global-landing-page-links {
		font-size: 2rem;
		margin-right: 0;
		margin-left: 30px;
		margin-top: 30px;
	}

	.cancel-button {
		display: block;
	}
}

@media (max-width: 545px) {
	.red-global-logo img:nth-child(1) {
		display: none;
	}

	.red-global-logo img:nth-child(2) {
		display: inline;
	}

	.red-global-logo {
		margin: 0 8px 0 14px;
	}

	.red-current-module {
		padding-left: 8px;
	}

	.red-global-menu {
		padding: 0 12px;
		height: 64px;
		background-color: white;
	}

	.red-current-module {
		font-size: 1rem;
		line-height: 20px;
	}
}