.footer_container {
    width: 100%;
    height: 72px;
    background-color: var(--white-color);
    padding: 0px 20px
}

.footer_container p {
    font-size: 1rem;
    color: var(--light-text-color);
}

.call_centre_number{
	font-weight: 500;
	color: var(--alt-text-color-tint);
}

.call_centre_number > span:first-of-type{
	color: var(--light-text-color);
}

.call_centre_number > span:last-of-type{
	color: var(--text-green);
}