* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.page_loader_container {
	width: 100%;
	height: 100vh;
	background-color: white;
	display: grid;
	place-content: center;
	align-content: center;
	align-content: center;
}

.loader {
	position: relative;
	height: 200px;
	width: 200px;
	border-radius: 50%;
	align-items: center;
	text-align: center;
	padding-top: 28px;
	padding-right: 8px;
	background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
	animation: animate-line 2s linear infinite;
}

.loader:before {
	content: "";
	position: absolute;
	top: 6px;
	left: 6px;
	right: 6px;
	bottom: 6px;
	background: rgb(255, 255, 255);
	border-radius: 50%;
	z-index: 1000;
}

.loader:after {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: linear-gradient(45deg, transparent, transparent 40%, #e5f403);
	z-index: 1000;
	z-index: 1;
	border-radius: 50%;
	filter: blur(10px);
}

.top {
	position: relative;
	top: 0;
	left: 0;
	margin: -170px auto;
	z-index: 10000;
	width: 150px;
	height: 150px;
	opacity: 1;
	animation: image_opacity 2.5s ease-in-out infinite;
}

@keyframes animate-line {
	0% {
		transform: rotate(0deg);
		filter: hue-rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		filter: hue-rotate(20deg);
	}
}

@keyframes image_opacity {
	50% {
		opacity: 0.2;
	}

	100% {
		opacity: 1;
	}
}
