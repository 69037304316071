h3 {
	font-size: 5.25rem;
}
.error_img_size {
	width: 30rem;
	height: 30rem;
	max-width: 30rem;
	max-height: 30rem;
	animation: float 6s ease-in-out infinite alternate;
}
.description {
	max-width: 39rem;
	min-height: 3.5rem;
}
.error_page_title {
	font-style: normal;
	font-weight: 500;
	font-size: 3.75rem;
	line-height: 4.5rem;
}

.error_page_text {
	font-weight: 400;
	font-size: 1.36rem;
	line-height: 1.8rem;
	color: var(--gray);
}
.text_box {
	min-height: 4.3rem;
	width: 100%;
}

@keyframes float {
	0% {
		transform: translatey(2.25rem);
	}

	50% {
		transform: translateY(0rem);
	}

	100% {
		transform: translatey(4.25rem);
	}
}
