.personnel_card_align {
	padding: 0px 20px;
}
.info-container {
	margin-top: 9px;
}

.personnel-image {
	width: 173px;
}

.info-avatar span {
	font-size: 100px !important;
}

.secondInfo-container {
	margin-top: -17px;
}

.card-title{
	font-size: 14px;
	color: var(--light-text-color);
	font-weight: lighter;
}

.payment-card{
	padding:20px;
	border: 1px solid var(--border-stoke);
	border-radius: 8px;
	background-color: transparent !important;
}

.paid{
	color: var(--text-green);
}

.unpaid{
	color: var(--danger);
}

.currency{
	font-size: 18px;
	font-weight: lighter;
}